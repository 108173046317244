import Pubnub from "pubnub";
import { UserBasic } from "./validation/auth-validation";

export function initPubnub(user: UserBasic) {
  return new Pubnub({
    publishKey: user.publishKey,
    subscribeKey: user.subscribeKey,
    uuid: user.uuid,
    // uuid: "6bd46491-641f-437b-9c70-73c0130b6815",
    // heartbeatInterval: 1000,
  });
}
